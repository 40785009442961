<template>
    <div>
        <div class="banner-container">
            <img class="career-img" src="../../assets/image/career/career-banner.jpg" />
            <p class="join-title">Join Our Team</p>
        </div>
        <el-breadcrumb id="breadcrumb-container" class="breadcrumb-container" separator="/">
            <el-breadcrumb-item class="breadcrumb-item" :to="{ path: '/career' }">Career</el-breadcrumb-item>
            <el-breadcrumb-item class="breadcrumb-item">Accountant</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="job-container">
            <p class="title">Accountant</p>
            <div class="description-container">
                <p class="subtitle">Job Descriptions</p>
                <ul class="requirement-list">
                    <li class="list-details"> Recording a wide range of financial transactions</li>
                    <li class="list-details"> Manage all accounting transactions</li>
                    <li class="list-details"> Publish financial statements in time</li>
                    <li class="list-details"> Preparing product or service reports by collecting and analyzing customer information.</li>
                    <li class="list-details"> Handle monthly, quarterly and annual closings</li>
                    <li class="list-details"> Reconcile accounts payable and receivable</li>
                    <li class="list-details"> Checking for accuracy in reports, figures and postings </li>
                    <li class="list-details"> Reconciling and reporting any discrepancies found in the records </li>
                    <li class="list-details"> Reviewing payment requests comply with policy & procedure </li>
                    <li class="list-details"> Ensure timely payments </li>
                    <li class="list-details"> Monitoring accounts and loans to ensure payments are current</li>
                    <li class="list-details"> Audit financial transactions and documentss </li>
                    <li class="list-details"> Reinforce financial data confidentiality and conduct database backups when necessarys </li>
                    <li class="list-details"> Comply with financial policies and regulations </li>
                    <li class="list-details"> Manage balance sheets and profit/loss statements </li>
                    <li class="list-details"> Report on the company’s financial health and liquidity </li>
                    <li class="list-details"> General financial oversight </li>
                    <li class="list-details"> Other tasks from management </li>
                </ul>
            </div>

            <div class="description-container">
                <p class="subtitle">Job Requirements</p>
                <ul class="requirement-list">
                    <li class="list-details"> Work experience as an Accountant</li>
                    <li class="list-details"> Excellent knowledge of accounting regulations and procedures, including the Generally Accepted Accounting Principles (GAAP)</li>
                    <li class="list-details"> Advanced MS Excel skills including Vlookups and pivot tables</li>
                    <li class="list-details"> Experience with general ledger functions</li>
                    <li class="list-details"> Strong attention to detail and good analytical skills</li>
                    <li class="list-details"> Hands-on experience with accounting software like SAP and QuickBooks is in Advantage</li>
                </ul>
            </div>
            <div class="description-container">
                <p class="subtitle">How to Apply</p>
                <p class="requirement-list">Interested candidates please submit your CV/resume with a photo and accompanied by cover letter and any other supporting documents to HR Department:</p>
                <div class="hr-contact">
                    <p class="hr-contact-details">Contact Person : HR Department</p>
                    <p class="hr-contact-details">Email: hr@u-pay.com</p>
                    <p class="hr-contact-details">Contact: 069 931 888 / 077 266 123 </p>
                    <p class="hr-contact-details">Website: www.u-pay.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
export default {
  setup() {
    onMounted(() => {
      var jobDescriptionElement = document.getElementById('breadcrumb-container')
      jobDescriptionElement.scrollIntoView()
      console.log('This qill only run after initial render')
    })
  }
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }
  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
.job-container {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding: 3% 25% 3% 25%;
    .title {
        color: #0277E3;
        text-align: center;
        font-size: 1.55rem;
        font-weight: bold;
    }
}

.description-container{
    padding-top: 8%;
    .subtitle {
        color: black;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
    }
    .requirement-list{
        margin: 3% 0% 5% 0%;
        .list-details {
            display: list-item;
            margin-bottom: 0.7%;
            list-style-type:disc;
        }
    }
    .salary{
        font-weight: bolder;
    }

    .hr-contact .hr-contact-details {
        margin-bottom: 3%;
        font-weight: bold;
    }
}

.breadcrumb-container {
    margin : 3% 3%;
}

.breadcrumb-item {
    color: darkgray;
    font-weight: 600;
    font-size: 1.0rem;
}

@media only screen and (max-width: 600px) {
    .job-container {
        display: table;
        margin-right: auto;
        margin-left: auto;
        padding: 3% 5% 3% 5%;
        .title {
            color: #0277E3;
            text-align: center;
            font-size: 1.10rem;
            font-weight: bold;
        }
    }
}
</style>
